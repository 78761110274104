<template>
  <v-container
    fluid
    v-if="!loading"
    class="pb-12"
    :key="reload"
    ref="container"
  >
    <v-row
      v-if="
        !isEditable &&
        edited.formInstanceStatusId != $constants.FORM_INSTANCE_STATUS.FINALIZED
      "
    >
      <v-col>
        <v-alert color="orange darken-1" class="mb-0 white--text">
          This form is not assigned to you.
          <span v-if="$store.getters.isWebAdmin">
            Select "Take Ownership" to start editing it.
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="
        !isPreview &&
        formInstance.formInstanceStatusId !=
          $constants.FORM_INSTANCE_STATUS.DRAFT
      "
    >
      <v-col class="grow" align="right" v-if="$store.getters.isWebAdmin">
        <FormAudits
          :formInstanceId="formInstance.id"
          @audited="
            formInstance.hasAudit = 1;
            edited.hasAudit = 1;
          "
        />
      </v-col>
      <v-col class="shrink" v-if="$store.getters.isWebAdmin">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="changeFormPrivacyStatus()"
            >
              <v-icon v-if="edited.isPrivate"> mdi-lock </v-icon>
              <v-icon v-if="!edited.isPrivate"> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>
            {{
              edited.isPrivate
                ? 'Click to set form to public'
                : 'Click to set form to private'
            }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink" align="right" v-if="$store.getters.isWebAdmin">
        <v-tooltip
          bottom
          :disabled="
            isEditable ||
            edited.formInstanceStatusId ==
              $constants.FORM_INSTANCE_STATUS.FINALIZED
          "
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              v-on="on"
              class="msaBlue white--text"
              @click="takeOwnership()"
              :disabled="
                isEditable ||
                edited.formInstanceStatusId ==
                  $constants.FORM_INSTANCE_STATUS.FINALIZED
              "
            >
              <v-icon> mdi-file-document-edit-outline </v-icon>
            </v-btn>
          </template>
          <span>Take Ownership</span>
        </v-tooltip>
      </v-col>
      <v-spacer
        v-if="$store.getters.user.type == $constants.USER_TYPES.NORMAL_USER"
      ></v-spacer>
      <v-col class="shrink">
        <v-menu
          offset-y
          v-if="
            formInstance.hasImage ||
            formInstance.hasAudit ||
            formInstance.hasAttachment
          "
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-blur
                  class="msaBlue white--text"
                  v-bind="attrs"
                  v-on="{ ...menu, ...tooltip }"
                  :disabled="hasChanges"
                >
                  <v-icon> mdi-download </v-icon>
                </v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item @click="downloadForm()"> Download </v-list-item>
            <v-list-item
              @click="downloadForm(false)"
              v-if="formInstance.hasImage"
            >
              Download with high resolution images
            </v-list-item>
            <v-list-item
              @click="downloadForm(false, true)"
              v-if="formInstance.hasImage"
            >
              Download images only
            </v-list-item>
            <v-list-item
              @click="downloadForm(undefined, undefined, true)"
              v-if="formInstance.hasAudit"
            >
              Download with audits
            </v-list-item>
            <v-list-item
              @click="downloadForm(undefined, undefined, undefined, true)"
              v-if="formInstance.hasAttachment"
            >
              Download with attachments
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <v-btn
              v-blur
              class="msaBlue white--text"
              v-on="on"
              @click="downloadForm()"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row
      v-if="isPreview"
      :class="$vuetify.breakpoint.lgAndUp ? 'mb-0 pb-0' : 'mb-8'"
    >
      <v-col cols="12">
        <v-alert dense type="warning">
          This is a preview mode, none of the changes will be saved.
        </v-alert>
      </v-col>
    </v-row>
    <SectionNavigation
      v-if="$vuetify.breakpoint.mdAndDown"
      :sections="edited.sections"
      :availableWidth="sectionNavAvailableWidth"
      @goToSection="goToSection($event)"
    />
    <v-row justify="end">
      <v-col cols="4" v-if="$vuetify.breakpoint.lgAndUp">
        <SectionNavigation
          :sections="edited.sections"
          :availableWidth="sectionNavAvailableWidth"
          @goToSection="goToSection($event)"
        />
      </v-col>
      <v-col cols="12" lg="8" ref="main">
        <v-row v-if="formInstance.isPastSync" dense>
          <v-col>
            <v-alert text color="info" dense class="mb-0 pb-0">
              <strong>Please Note:</strong>
              <p>
                If this form does not appear on mobile devices,
                <a href="#" @click.prevent="touchTheForm">click here</a> to make
                it available to mobile devices for an additional 8 days.
              </p>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <!-- summary -->
          <v-col cols="12">
            <v-card ref="form-summary">
              <v-card-title class="lightGrey">
                <v-row align="center">
                  <v-col class="text-no-wrap mr-auto" cols="auto">
                    Form Summary
                  </v-col>
                  <v-col class="text-body-2 text-no-wrap" cols="auto">
                    <v-row no-gutters justify="end">
                      <v-col class="ml-4">
                        <v-icon color="red">mdi-alert</v-icon>
                        {{ caCounts.high }} High
                      </v-col>
                      <v-col class="ml-4">
                        <v-icon color="orange">mdi-alert</v-icon>
                        {{ caCounts.medium }} Medium
                      </v-col>
                      <v-col class="ml-4">
                        <v-icon color="green">mdi-alert</v-icon>
                        {{ caCounts.low }} Low
                      </v-col>
                      <v-col class="ml-4">
                        <v-icon>mdi-alert</v-icon>
                        {{ caCounts.resolved }} / {{ caCounts.total }}
                      </v-col>
                      <v-col class="text-center ml-4">
                        <div
                          :class="statusClass(edited.formInstanceStatusId)"
                          :style="{ 'border-radius': '4px', width: '100px' }"
                        >
                          {{ statusText(edited.formInstanceStatusId) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text class="pt-4">
                <v-row v-if="edited.createdOnMobileAt">
                  <v-col class="font-weight-bold">
                    Date created:
                    {{
                      edited.createdOnMobileAt
                        | filterAsLocalDate('LLL dd, yyyy')
                    }}
                    at
                    {{
                      edited.createdOnMobileAt | filterAsLocalTime('hh:mm a')
                    }}
                  </v-col>
                </v-row>
                <v-form ref="form">
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0">
                      <v-text-field
                        v-model="edited.userEnteredLabel"
                        dense
                        counter
                        maxlength="1000"
                        outlined
                        label="Title"
                        :rules="required"
                        :disabled="!isEditable"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <FormProjectAutoComplete
                        hide-details
                        label="Project"
                        :items="projects"
                        :value="edited.projectId"
                        :loading="isLoadingExtraData"
                        :disabled="disableProjectAndTag"
                        @change="updateProjectId"
                        :rules="projectRules"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-text-field
                        v-model="edited.createdByName"
                        outlined
                        dense
                        readonly
                        hide-details
                        label="Created By"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-text-field
                        v-model="edited.assignedToName"
                        outlined
                        dense
                        readonly
                        hide-details
                        label="Assigned To"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" :lg="edited.allowTags ? 4 : 6">
                      <DateTimePicker
                        clearable
                        :disabled="!isEditable"
                        :datetime="edited.timeIn"
                        label="Time In"
                        @input="edited.timeIn = $event"
                        :max="timeInMax"
                      />
                    </v-col>
                    <v-col cols="12" md="6" :lg="edited.allowTags ? 4 : 6">
                      <DateTimePicker
                        clearable
                        :disabled="!isEditable"
                        :datetime="edited.timeOut"
                        label="Time Out"
                        @input="edited.timeOut = $event"
                        :min="timeOutMin"
                      />
                    </v-col>
                    <v-col v-if="edited.allowTags" cols="12" lg="4">
                      <FormTagAutoComplete
                        :value="edited.tagIds"
                        :items="tags"
                        item-text="val"
                        multiple
                        :disabled="disableProjectAndTag"
                        hide-details
                        label="Tag(s)"
                        @change="edited.tagIds = $event.sort((a, b) => a - b)"
                        :deletable-chips="!disableProjectAndTag"
                        clearable
                        @tagAdded="
                          getNecessaryData();
                          edited.tagIds.push($event.id);
                        "
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- sections -->
          <v-col
            cols="12"
            v-for="(section, sectionIndex) in edited.sections"
            :key="section.id"
          >
            <v-card ref="sections">
              <v-card-title class="lightGrey">
                <v-row align="center">
                  <v-col cols="auto" class="mr-auto">
                    {{ section.name }}
                  </v-col>
                  <v-col cols="auto">
                    <v-switch
                      v-model="section.isCollapsed"
                      :false-value="0"
                      :true-value="1"
                      @change="sectionNA(section, sectionIndex, $event)"
                      class="ma-0 pa-0"
                      color="msaBlue"
                      dense
                      hide-details
                      label="Not applicable"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-expand-transition>
                <v-card-text class="pa-0" v-if="!section.isCollapsed">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(element, elementIndex) in section.elements"
                          :key="element.id"
                        >
                          <td
                            class="msaBlue--text"
                            :style="inputRequiredStyle(element)"
                          >
                            <v-row>
                              <v-col class="grow">
                                <component
                                  :is="elementTypes[element.typeId]"
                                  :element="element"
                                  :isEditable="isEditable"
                                  :users="users"
                                  :isFinalized="
                                    edited.formInstanceStatusId ==
                                    $constants.FORM_INSTANCE_STATUS.FINALIZED
                                  "
                                  @showNewCa="
                                    selectedSection = section;
                                    selectedElement = element;
                                    showNewCa();
                                  "
                                  @showElementDetail="
                                    selectedSection = section;
                                    selectedElement = element;
                                    $refs.elementDetails.show();
                                  "
                                  @updateElement="
                                    updateElement(
                                      sectionIndex,
                                      elementIndex,
                                      $event,
                                    );
                                    element[$event.key] = $event.value;
                                  "
                                />
                              </v-col>
                              <v-col
                                class="shrink"
                                v-show="isDeletableElement(element.id, section)"
                              >
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-blur
                                      icon
                                      v-on="on"
                                      color="red"
                                      class="mt-2"
                                      @click="
                                        selectedSection = section;
                                        selectedElement = element;
                                        deleteElement(element.id, elementIndex);
                                      "
                                    >
                                      <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-expand-transition>
              <v-expand-transition>
                <v-card-actions
                  v-show="
                    section.typeId ==
                      $constants.FORM_INSTANCE_SECTION_TYPES
                        .ADDITIONAL_HAZARD &&
                    isEditable &&
                    !section.isCollapsed
                  "
                >
                  <v-btn
                    block
                    class="msaBlue white--text"
                    @click="addAdditionalHazard()"
                  >
                    Add an Additional Hazard
                  </v-btn>
                </v-card-actions>
              </v-expand-transition>
            </v-card>
          </v-col>

          <!-- signatures -->
          <v-col cols="12" v-show="!isLoadingExtraData">
            <v-card ref="signatures">
              <v-card-title class="lightGrey"> Signatures </v-card-title>
              <v-lazy>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td
                          class="py-2"
                          :style="
                            signatureCellStyle(
                              $constants.SIGNATURE_TYPES.CREATED_BY,
                            )
                          "
                        >
                          <FormSignature
                            :areAllRequiredFieldsFilled="
                              areAllRequiredFieldsFilled
                            "
                            :signature="edited.signatures.createdBy"
                            :typeId="$constants.SIGNATURE_TYPES.CREATED_BY"
                            :binaryType="$constants.BINARY_TYPES.FORM_SIGNATURE"
                            :isEditable="isEditable"
                            @addSignature="
                              addSignature(
                                $constants.SIGNATURE_TYPES.CREATED_BY,
                              )
                            "
                            @viewOnMap="viewOnMap(edited.signatures.createdBy)"
                            :isPreview="isPreview"
                            :isAssignable="isAssignable"
                          />
                        </td>
                      </tr>
                      <tr v-if="hasSignature(edited.signatures.createdBy)">
                        <td
                          class="py-2"
                          :style="
                            signatureCellStyle(
                              $constants.SIGNATURE_TYPES.SUPERVISOR,
                            )
                          "
                        >
                          <FormSignature
                            :areAllRequiredFieldsFilled="
                              areAllRequiredFieldsFilled
                            "
                            :signature="edited.signatures.supervisor"
                            :typeId="$constants.SIGNATURE_TYPES.SUPERVISOR"
                            :binaryType="$constants.BINARY_TYPES.FORM_SIGNATURE"
                            :isEditable="isEditable"
                            @addSignature="
                              addSignature(
                                $constants.SIGNATURE_TYPES.SUPERVISOR,
                              )
                            "
                            @viewOnMap="viewOnMap(edited.signatures.supervisor)"
                            @assign="$refs.assignForm.show(1)"
                            :isPreview="isPreview"
                            :isAssignable="isAssignable"
                          />
                        </td>
                      </tr>
                      <tr v-if="hasSignature(edited.signatures.createdBy)">
                        <td
                          class="py-2"
                          :style="
                            signatureCellStyle(
                              $constants.SIGNATURE_TYPES.MANAGER,
                            )
                          "
                        >
                          <FormSignature
                            :areAllRequiredFieldsFilled="
                              areAllRequiredFieldsFilled
                            "
                            :signature="edited.signatures.manager"
                            :typeId="$constants.SIGNATURE_TYPES.MANAGER"
                            :binaryType="$constants.BINARY_TYPES.FORM_SIGNATURE"
                            :isEditable="isEditable"
                            @addSignature="
                              addSignature($constants.SIGNATURE_TYPES.MANAGER)
                            "
                            @viewOnMap="viewOnMap(edited.signatures.manager)"
                            @assign="$refs.assignForm.show(2)"
                            :isPreview="isPreview"
                            :isAssignable="isAssignable"
                          />
                        </td>
                      </tr>
                      <tr v-if="hasSignature(edited.signatures.createdBy)">
                        <td
                          class="py-2"
                          :style="
                            signatureCellStyle(
                              $constants.SIGNATURE_TYPES.SAFETY_REP,
                            )
                          "
                        >
                          <FormSignature
                            :areAllRequiredFieldsFilled="
                              areAllRequiredFieldsFilled
                            "
                            :signature="edited.signatures.safetyRep"
                            :typeId="$constants.SIGNATURE_TYPES.SAFETY_REP"
                            :binaryType="$constants.BINARY_TYPES.FORM_SIGNATURE"
                            :isEditable="isEditable"
                            @addSignature="
                              addSignature(
                                $constants.SIGNATURE_TYPES.SAFETY_REP,
                              )
                            "
                            @viewOnMap="viewOnMap(edited.signatures.safetyRep)"
                            @assign="$refs.assignForm.show(3)"
                            :isPreview="isPreview"
                            :isAssignable="isAssignable"
                          />
                        </td>
                      </tr>
                      <template
                        v-for="signature in edited.signatures.additional"
                      >
                        <tr
                          v-if="hasSignature(edited.signatures.createdBy)"
                          :key="signature.id"
                        >
                          <td class="py-2">
                            <FormSignature
                              :areAllRequiredFieldsFilled="
                                areAllRequiredFieldsFilled
                              "
                              :signature="signature"
                              :typeId="$constants.SIGNATURE_TYPES.ADDITIONAL"
                              :binaryType="
                                $constants.BINARY_TYPES.FORM_SIGNATURE
                              "
                              @viewOnMap="viewOnMap(signature)"
                            />
                          </td>
                        </tr>
                      </template>
                      <tr
                        v-if="
                          edited.formInstanceStatusId !=
                            $constants.FORM_INSTANCE_STATUS.FINALIZED &&
                          hasSignature(edited.signatures.createdBy)
                        "
                      >
                        <td class="py-2">
                          <FormSignature
                            :areAllRequiredFieldsFilled="
                              areAllRequiredFieldsFilled
                            "
                            :typeId="$constants.SIGNATURE_TYPES.ADDITIONAL"
                            :binaryType="$constants.BINARY_TYPES.FORM_SIGNATURE"
                            :isEditable="isEditable"
                            @addSignature="
                              addSignature(
                                $constants.SIGNATURE_TYPES.ADDITIONAL,
                              )
                            "
                            @assign="
                              $refs.assignForm.show(
                                $constants.SIGNATURE_TYPES.ADDITIONAL,
                              )
                            "
                            :isPreview="isPreview"
                            :isAssignable="isAssignable"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-lazy>
              <v-lazy>
                <AddSignature
                  ref="addSignature"
                  :key="reloadAddSignature"
                  :showContacts="
                    selectedSignatureTypeId ==
                    $constants.SIGNATURE_TYPES.ADDITIONAL
                  "
                  :contacts="contacts"
                  :users="users"
                  :signatureTypeId="selectedSignatureTypeId"
                  @signatureCreated="
                    onSignatureCreated($event, selectedSignatureTypeId)
                  "
                  @reload="reloadAddSignature -= 1"
                  @getContacts="getNecessaryData()"
                />
              </v-lazy>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <ElementDetails
      ref="elementDetails"
      :element="selectedElement"
      :users="users"
      :isEditable="isEditable"
      :isFinalized="isFinalized"
      :selectedElementOriginal="selectedElementOriginal"
      @addImage="addImage($event)"
      @deleteImage="deleteImage($event)"
      @addNote="addNote($event)"
      @deleteNote="deleteNote($event)"
      @deleteCa="deleteCa($event)"
      @updateCa="updateCa($event)"
      @showCaDetails="showCaDetails($event)"
      @showNewCa="showNewCa()"
      @close="
        selectedElement = null;
        selectedSection = null;
        selectedCa = null;
      "
      @selectedCa="selectedCa = $event"
    />

    <SignatureMap
      ref="signatureMap"
      :latitude="selectedSignatureLocation.latitude"
      :longitude="selectedSignatureLocation.longitude"
    />

    <CorrectiveActionDetails
      ref="caDetails"
      :users="users"
      :correctiveAction="selectedCa"
      :isCaEditable="isSelectedCaEditable"
      :severities="severities"
      :frequencies="frequencies"
      :probabilities="probabilities"
      @saveCa="saveCa($event)"
      @cancel="closeCaDetailsDialog()"
      @addImage="addCaImage($event)"
      @deleteImage="deleteCaImage($event)"
      @addNote="addCaNote($event)"
      @deleteNote="deleteCaNote($event)"
    />

    <v-bottom-navigation
      fixed
      background-color="white"
      v-show="!this.isPreview"
    >
      <template v-slot:default>
        <v-container fluid :class="isMobile ? '' : 'px-12'">
          <v-row>
            <v-col align="right">
              <v-btn
                v-if="hasChanges"
                @click="save()"
                class="amber lighten-1"
                height="36"
                :style="{ color: '#3564ac !important', 'border-radius': '4px' }"
              >
                <div>
                  <v-icon>mdi-content-save-outline</v-icon>
                  SAVE
                </div>
              </v-btn>
              <AssignFormDialog
                ref="assignForm"
                :users="users"
                @onAssign="assignForm($event)"
                :isAssignable="isAssignable"
              />
              <v-btn
                class="msaBlue ml-4"
                height="36"
                :style="{
                  color:
                    !isFinalizable || !isEditable
                      ? 'grey !important'
                      : 'white !important',
                  'border-radius': '4px',
                }"
                :disabled="!isFinalizable || !isEditable"
                @click="finalizeForm()"
              >
                <div>
                  <v-icon> mdi-file-document-check-outline </v-icon>
                  FINALIZE
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import AddSignature from '@/components/AddSignature.vue';
import AssignFormDialog from '@/components/FormInstances/AssignFormDialog.vue';
import CheckboxElement from '@/components/FormInstances/CheckboxElement.vue';
import CommonHazards from '@/components/FormInstances/CommonHazards.vue';
import CorrectiveActionDetails from '@/components/FormInstances/CorrectiveActionDetails.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';
import ElementDetails from '@/components/FormInstances/ElementDetails.vue';
import FormAudits from '@/components/FormInstances/FormAudits.vue';
import FormProjectAutoComplete from '@/components/FormInstances/FormProjectAutoComplete.vue';
import FormSignature from '@/components/FormInstances/FormSignature.vue';
import FormTagAutoComplete from '@/components/FormInstances/FormTagAutoComplete.vue';
import InspectionItem from '@/components/FormInstances/InspectionItem.vue';
import InstructionsElement from '@/components/FormInstances/InstructionsElement.vue';
import SectionNavigation from '@/components/FormInstances/SectionNavigation.vue';
import SignatureMap from '@/components/SignatureMap.vue';
import TextField from '@/components/FormInstances/TextField.vue';
import YesNo from '@/components/FormInstances/YesNo.vue';
import DropDown from '@/components/FormInstances/DropDown.vue';
import DateTimePickerQuestion from '@/components/FormInstances/DateTimePickerQuestion.vue';
import PdfQuestion from '@/components/FormInstances/PdfQuestion.vue';
import ImageQuestion from '@/components/FormInstances/ImageQuestion.vue';
import {
  phantomAccessCheck,
  urlOrganizationCheck,
} from '../global/functions.js';

export default {
  name: 'FormDetailsPage',
  components: {
    AddSignature,
    AssignFormDialog,
    CheckboxElement,
    CommonHazards,
    CorrectiveActionDetails,
    DateTimePicker,
    ElementDetails,
    FormAudits,
    FormProjectAutoComplete,
    FormSignature,
    FormTagAutoComplete,
    InspectionItem,
    InstructionsElement,
    SectionNavigation,
    SignatureMap,
    TextField,
    YesNo,
    DropDown,
    DateTimePickerQuestion,
    PdfQuestion,
    ImageQuestion,
  },
  data() {
    return {
      floatingSectionWidth: 120,
      loading: true,
      tags: [],
      projects: [],
      formInstance: {},
      edited: {},
      users: [],
      timeInMenu: false,
      timeOutMenu: false,
      elementTypes: {
        [this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM]: 'InspectionItem',
        [this.$constants.FORM_ELEMENT_TYPES.TEXT_FIELD]: 'TextField',
        [this.$constants.FORM_ELEMENT_TYPES.CHECKBOX]: 'CheckboxElement',
        [this.$constants.FORM_ELEMENT_TYPES.YES_NO]: 'YesNo',
        [this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS]: 'CommonHazards',
        [this.$constants.FORM_ELEMENT_TYPES.INSTRUCTIONS]:
          'InstructionsElement',
        [this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE]: 'DropDown',
        [this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI]: 'DropDown',
        [this.$constants.FORM_ELEMENT_TYPES.DATE]: 'DateTimePickerQuestion',
        [this.$constants.FORM_ELEMENT_TYPES.TIME]: 'DateTimePickerQuestion',
        [this.$constants.FORM_ELEMENT_TYPES.PDF]: 'PdfQuestion',
        [this.$constants.FORM_ELEMENT_TYPES.IMAGE]: 'ImageQuestion',
      },
      required: [(v) => !!v || 'This field is required'],
      highCa: ['1A', '1B', '1C', '1D', '2A', '2B', '2C', '3A', '3B', '4A'],
      mediumCa: ['2D', '3C', '3D', '4B', '4C'],
      lowCa: ['4D', '5A', '5B', '5C', '5D'],
      binaries: {},
      defaultCorrectiveActionProps: {
        statusId: 2,
        hazardText: '',
        controlText: '',
        assignedStudentUserId: this.$store.getters.user.id,
        assignedToName:
          this.$store.getters.user.firstName +
          ' ' +
          this.$store.getters.user.lastName,
        lastResolvedAt: '',
        notes: [],
        images: [],
        hazardRankSeverity: '',
        lastResolvedByName: '',
        hazardRankProbability: '',
        frequencyCode: '',
        lastResolvedBy: '',
      },
      selectedSection: null,
      selectedElement: null,
      selectedSignatureTypeId: null,
      selectedCa: null,
      latitude: '',
      longitude: '',
      selectedSignatureLocation: {
        latitude: '',
        longitude: '',
      },
      reload: false,
      contacts: [],
      reloadAddSignature: -1,
      severities: [],
      frequencies: [],
      probabilities: [],
      sectionNavAvailableWidth: 0,
      isLoadingExtraData: true,
      changes: {
        sections: {},
        elements: {
          create: {},
          update: {},
        },
        signatures: [],
        tagIds: [],
        notes: {},
        images: {},
        correctiveActions: {
          create: {},
          update: {},
        },
        correctiveActionNotes: {},
        correctiveActionImages: {},
      },
    };
  },
  computed: {
    isFinalized() {
      return (
        this.formInstance.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      );
    },
    isPreview() {
      return (
        this.$route.name == 'FormTemplatePreview' ||
        this.$route.name == 'PublicFormTemplatePreview'
      );
    },
    projectRules() {
      return this.isPreview ? [] : this.required;
    },
    isSelectedCaEditable() {
      // ca cannot be edited for a finalized form
      if (
        this.formInstance.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return false;
      }

      // ca can be edited if form is editable
      if (this.isEditable || this.selectedCa == null) {
        return true;
      }

      // newly added ca can always be edited
      if (!Number.isInteger(this.selectedCa.id)) {
        return true;
      }

      // ca can be edited if it is assigned to the current user
      const section = this.formInstance.sections.find(
        (section) => section.id == this.selectedCa.formInstanceSectionId,
      );
      const element = section.elements.find(
        (element) => element.id == this.selectedCa.formInstanceElementId,
      );
      const ca = element.correctiveActions.find(
        (ca) => ca.id == this.selectedCa.id,
      );
      return ca.assignedStudentUserId == this.$store.getters.user.id;
    },
    // only applies to elements belonging to additional hazard sections before save
    isDeletableElement() {
      return (elementId, section) => {
        if (
          section.typeId ==
          this.$constants.FORM_INSTANCE_SECTION_TYPES.ADDITIONAL_HAZARD
        ) {
          return !Number.isInteger(elementId);
        } else {
          return false;
        }
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isEditable() {
      return (
        this.isFormAssignedToCurrentUser &&
        this.formInstance.formInstanceStatusId !=
          this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      );
    },
    inputRequiredStyle() {
      return (element) => {
        if (!element.inputRequired) {
          return { 'padding-left': '21px' };
        }

        switch (element.typeId) {
          case this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM:
          case this.$constants.FORM_ELEMENT_TYPES.YES_NO:
          case this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS:
            return element.statusId <= 0
              ? { 'border-left': '5px red solid' }
              : { 'border-left': '5px #4CAF50 solid' };

          case this.$constants.FORM_ELEMENT_TYPES.TEXT_FIELD:
            return element.userResponseText.trim().length == 0
              ? { 'border-left': '5px red solid' }
              : { 'border-left': '5px #4CAF50 solid' };
          case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI:
            return element.selected.length == 0
              ? this.isElementNotApplicable(element.statusId)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'border-left': '5px #4CAF50 solid' };
          case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE:
            return element.selected == 0
              ? this.isElementNotApplicable(element.statusId)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'border-left': '5px #4CAF50 solid' };
          case this.$constants.FORM_ELEMENT_TYPES.DATE:
          case this.$constants.FORM_ELEMENT_TYPES.TIME:
            return element.dateTimeInput == ''
              ? this.isElementNotApplicable(element.statusId)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'border-left': '5px #4CAF50 solid' };
          case this.$constants.FORM_ELEMENT_TYPES.PDF:
            return element.statusId ==
              this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'border-left': '5px red solid' };

          default:
            return '';
        }
      };
    },
    hasChanges() {
      if (
        JSON.stringify(this.formInstance) == '{}' &&
        JSON.stringify(this.edited) == '{}'
      ) {
        return false;
      }
      const edited = {
        projectId: this.edited.projectId,
        userEnteredLabel: this.edited.userEnteredLabel,
        timeIn: this.edited.timeIn,
        timeOut: this.edited.timeOut,
        tagIds: JSON.parse(JSON.stringify(this.edited.tagIds)),
        sections: JSON.parse(JSON.stringify(this.edited.sections)),
        signatures: JSON.parse(JSON.stringify(this.edited.signatures)),
      };
      const original = {
        projectId: this.formInstance.projectId,
        userEnteredLabel: this.formInstance.userEnteredLabel,
        timeIn: this.formInstance.timeIn,
        timeOut: this.formInstance.timeOut,
        tagIds: JSON.parse(JSON.stringify(this.formInstance.tagIds)),
        sections: JSON.parse(JSON.stringify(this.formInstance.sections)),
        signatures: JSON.parse(JSON.stringify(this.formInstance.signatures)),
      };

      if (
        this.formInstance.formInstanceStatusId !=
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return JSON.stringify(edited) != JSON.stringify(original);
      } else {
        return (
          edited.projectId != original.projectId ||
          JSON.stringify(edited.tagIds) != JSON.stringify(original.tagIds)
        );
      }
    },
    caCounts() {
      const result = {
        high: 0,
        medium: 0,
        low: 0,
        resolved: 0,
        unresolved: 0,
        total: 0,
        hasCaAssignedToCurrentUser: false,
      };
      this.edited.sections.forEach((section, sectionIndex) => {
        section.elements.forEach((element, elementIndex) => {
          element.correctiveActions.forEach((ca, caIndex) => {
            result.total += 1;

            const severityProbability = `${ca.hazardRankSeverity}${ca.hazardRankProbability}`;

            if (
              ca.statusId == this.$constants.CORRECTIVE_ACTION_STATUS.RESOLVED
            ) {
              result.resolved += 1;
            } else {
              result.unresolved += 1;
            }

            if (this.highCa.includes(severityProbability)) {
              result.high += 1;
            }
            if (this.mediumCa.includes(severityProbability)) {
              result.medium += 1;
            }
            if (this.lowCa.includes(severityProbability)) {
              result.low += 1;
            }

            const isCaNew = !Number.isInteger(ca.id);
            if (!isCaNew) {
              const originalCa =
                this.formInstance.sections[sectionIndex].elements[elementIndex]
                  .correctiveActions[caIndex];
              if (
                originalCa.assignedStudentUserId == this.$store.getters.user.id
              ) {
                result.hasCaAssignedToCurrentUser = true;
              }
            } else {
              result.hasCaAssignedToCurrentUser = true;
            }
          });
        });
      });
      return result;
    },
    statusClass() {
      return (statusId) => {
        switch (statusId) {
          case this.$constants.FORM_INSTANCE_STATUS.DRAFT:
            return 'grey white--text';
          case this.$constants.FORM_INSTANCE_STATUS.SUBMITTED:
            return 'msaBlue white--text';
          case this.$constants.FORM_INSTANCE_STATUS.FINALIZED:
            return 'green white--text';
          case this.$constants.FORM_INSTANCE_STATUS.REVIEWED:
            return 'msaOrange white--text';
          case this.$constants.FORM_INSTANCE_STATUS.ASSIGNED:
            return 'red white--text';
          default:
            return '';
        }
      };
    },
    statusText() {
      return (statusId) => {
        switch (statusId) {
          case this.$constants.FORM_INSTANCE_STATUS.DRAFT:
            return 'Draft';
          case this.$constants.FORM_INSTANCE_STATUS.SUBMITTED:
            return 'Submitted';
          case this.$constants.FORM_INSTANCE_STATUS.FINALIZED:
            return 'Finalized';
          case this.$constants.FORM_INSTANCE_STATUS.REVIEWED:
            return 'Reviewed';
          case this.$constants.FORM_INSTANCE_STATUS.ASSIGNED:
            return 'Assigned';
          default:
            return '';
        }
      };
    },
    areAllRequiredFieldsFilled() {
      if (
        !this.edited.projectId ||
        this.edited.userEnteredLabel.trim().length == 0
      ) {
        return false;
      }

      let result = true;
      sectionLoop: for (const section of this.edited.sections) {
        for (const element of section.elements) {
          if (!element.inputRequired) {
            continue;
          }

          switch (element.typeId) {
            case this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM:
            case this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS:
            case this.$constants.FORM_ELEMENT_TYPES.YES_NO:
              if (element.statusId <= 0) {
                result = false;
                break sectionLoop;
              }
              break;
            case this.$constants.FORM_ELEMENT_TYPES.TEXT_FIELD:
              if (element.userResponseText.trim().length == 0) {
                result = false;
                break sectionLoop;
              }
              break;
            case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE:
              if (this.isElementNotApplicable(element.statusId)) {
                continue;
              }
              if (element.selected == 0) {
                result = false;
                break sectionLoop;
              }
              break;
            case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI:
              if (this.isElementNotApplicable(element.statusId)) {
                continue;
              }
              if (element.selected.length == 0) {
                result = false;
                break sectionLoop;
              }
              break;
            case this.$constants.FORM_ELEMENT_TYPES.DATE:
            case this.$constants.FORM_ELEMENT_TYPES.TIME:
              if (this.isElementNotApplicable(element.statusId)) {
                continue;
              }
              if (element.dateTimeInput == '') {
                result = false;
                break sectionLoop;
              }
              break;
            case this.$constants.FORM_ELEMENT_TYPES.PDF:
              if (
                element.statusId ==
                this.$constants.FORM_ELEMENT_STATUS.UNSATISFACTORY
              ) {
                result = false;
                break sectionLoop;
              }
              break;
          }
        }
      }
      return result;
    },
    hasSignature() {
      return (signature) => {
        return JSON.stringify(signature) != '{}';
      };
    },
    isComplete() {
      if (!this.areAllRequiredFieldsFilled) {
        return false;
      }

      if (!this.hasSignature(this.edited.signatures.createdBy)) {
        return false;
      }

      return true;
    },
    signatureCellStyle() {
      return (signatureTypeId) => {
        switch (signatureTypeId) {
          case this.$constants.SIGNATURE_TYPES.CREATED_BY:
            return this.hasSignature(this.edited.signatures.createdBy)
              ? { 'border-left': '5px #4CAF50 solid' }
              : { 'border-left': '5px red solid' };
          case this.$constants.SIGNATURE_TYPES.SUPERVISOR:
            return this.edited.supervisorApprovalRequired
              ? this.hasSignature(this.edited.signatures.supervisor)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'padding-left': '21px' };
          case this.$constants.SIGNATURE_TYPES.MANAGER:
            return this.edited.managerApprovalRequired
              ? this.hasSignature(this.edited.signatures.manager)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'padding-left': '21px' };
          case this.$constants.SIGNATURE_TYPES.SAFETY_REP:
            return this.edited.safetyRepApprovalRequired
              ? this.hasSignature(this.edited.signatures.safetyRep)
                ? { 'border-left': '5px #4CAF50 solid' }
                : { 'border-left': '5px red solid' }
              : { 'padding-left': '21px' };
          default:
            return '';
        }
      };
    },
    isFinalizable() {
      if (
        !this.isComplete ||
        this.edited.formInstanceStatusId ==
          this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return false;
      }

      if (
        (this.edited.managerApprovalRequired &&
          !this.hasSignature(this.edited.signatures.manager)) ||
        (this.edited.supervisorApprovalRequired &&
          !this.hasSignature(this.edited.signatures.supervisor)) ||
        (this.edited.safetyRepApprovalRequired &&
          !this.hasSignature(this.edited.signatures.safetyRep))
      ) {
        return false;
      }

      return true;
    },
    isAssignable() {
      // not completed or finalized
      if (
        !this.isComplete ||
        this.edited.formInstanceStatusId ==
          this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return false;
      }

      // not assigned to current user
      if (!this.isFormAssignedToCurrentUser) {
        return false;
      }

      return true;
    },
    timeInMax() {
      return this.edited.timeOut ? this.edited.timeOut : undefined;
    },
    timeOutMin() {
      return this.edited.timeIn ? this.edited.timeIn : undefined;
    },
    userFullname() {
      return (
        this.$store.getters.user.firstName +
        ' ' +
        this.$store.getters.user.lastName
      );
    },
    statusChanged() {
      return (
        this.formInstance.formInstanceStatusId !=
        this.edited.formInstanceStatusId
      );
    },
    formUpdateUrl() {
      if (
        this.formInstance.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return 'update-finalized-form-instance?format=json';
      }

      if (this.isFormAssignedToCurrentUser) {
        return 'update-form-instance?format=json';
      }

      if (this.$store.getters.isWebAdmin) {
        return 'update-form-instance-as-web-admin?format=json';
      }

      if (this.caCounts.hasCaAssignedToCurrentUser) {
        return 'update-assigned-corrective-actions?format=json';
      }

      // at this point, the form is not finalized, not assigned to the current user, current user is not a web admin and there is no ca assigned to the current user, if the user ever signed the form, they can only update the form project and tags.

      // ***DO NOT MERGE THIS WITH THE FIRST IF STATEMENT AS THE LOGIC IS DIFFERENT***
      if (this.isCurrentUserSigner) {
        return 'update-finalized-form-instance?format=json';
      }

      return '';
    },
    disableProjectAndTag() {
      if (this.isLoadingExtraData) {
        return true;
      }

      return !(
        this.isFormAssignedToCurrentUser ||
        this.$store.getters.isWebAdmin ||
        this.isCurrentUserSigner ||
        this.caCounts.hasCaAssignedToCurrentUser
      );
    },
    isFormAssignedToCurrentUser() {
      return (
        this.formInstance.assignedStudentUserId == this.$store.getters.user.id
      );
    },
    isCurrentUserSigner() {
      let result = false;
      loop: for (const signatureType in this.formInstance.signatures) {
        if (signatureType == 'additional') {
          for (const signature of this.edited.signatures.additional) {
            if (signature.studentUserId == this.$store.getters.user.id) {
              result = true;
              break loop;
            }
          }
        } else {
          const signature = this.edited.signatures[signatureType];
          if (signature.studentUserId == this.$store.getters.user.id) {
            result = true;
            break;
          }
        }
      }
      return result;
    },
    selectedElementOriginal() {
      if (!this.selectedElement) {
        return null;
      }
      const sectionIndex = this.formInstance.sections.findIndex(
        (section) => section.id == this.selectedElement.formInstanceSectionId,
      );
      const elementIndex = this.formInstance.sections[
        sectionIndex
      ].elements.findIndex((element) => element.id == this.selectedElement.id);
      return this.formInstance.sections[sectionIndex].elements[elementIndex];
    },
  },
  mounted() {
    if (
      this.$route.name == 'FormDetails' &&
      !phantomAccessCheck(this, 'Form Details')
    ) {
      return;
    }
    if (!urlOrganizationCheck(this, 'Forms')) {
      return;
    }
    this.init()
      .then(() => {
        this.getNecessaryData();
      })
      .catch((e) => {
        if (e.response && e.response.data) {
          this.$root.showMessage(
            '',
            e.response.data,
            () => this.$router.replace({ name: 'Forms' }),
            () => null,
            'ok',
            '',
          );
        }
        return e;
      });
    this.getLocation();
  },
  created() {
    window.addEventListener('resize', this.setupSectionNavAvailableWidth);
    window.addEventListener('beforeunload', this.unsavedWarning);
  },
  destroyed() {
    window.removeEventListener('resize', this.setupSectionNavAvailableWidth);
    window.removeEventListener('beforeunload', this.unsavedWarning);
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isPreview) {
      next();
      return;
    }

    if (to.query && to.query.autoLogout) {
      next();
      return;
    }

    const isNewDraft =
      this.edited.createdOnMobileAt == this.edited.updatedAt &&
      this.edited.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.DRAFT;
    if (this.hasChanges) {
      this.$root.showMessage(
        'Are you sure?',
        "This form hasn't been saved yet.",
        async () => {
          if (isNewDraft) {
            await this.archiveFormOnLeave();
          }
          next();
        },
        () => next(false),
        'Confirm',
        'Cancel',
        true,
      );
      return;
    }

    if (isNewDraft) {
      await this.archiveFormOnLeave();
    }
    next();
  },
  methods: {
    async archiveFormOnLeave() {
      const url = 'archive-unsaved-draft-form-with-id?format=json';
      const params = {
        formInstanceId: this.edited.id,
        loaderText: 'Loading...',
      };

      await this.$axios.post(url, params);
    },
    updateElement(sectionIndex, elementIndex, payload) {
      this.$set(
        this.edited.sections[sectionIndex].elements[elementIndex],
        payload.key,
        payload.value,
      );
      this.checkElementChanges(sectionIndex, elementIndex);
    },
    checkElementChanges(sectionIndex, elementIndex) {
      const originalElement =
        this.formInstance.sections[sectionIndex].elements[elementIndex];
      const editedElement =
        this.edited.sections[sectionIndex].elements[elementIndex];
      // create a new object with only the properties that need to be updated
      const _editedElement = {
        formInstanceSectionId: editedElement.formInstanceSectionId,
        id: editedElement.id,
        name: editedElement.name,
        position: editedElement.position,
        revision: editedElement.revision,
        statusId: editedElement.statusId,
        typeId: editedElement.typeId,
        userResponseText: editedElement.userResponseText,
        inputRequired: editedElement.inputRequired,
        createdAt: editedElement.createdAt,
        updatedAt: new Date().toISOString(),
        selected: editedElement.selected,
        dateTimeInput: editedElement.dateTimeInput,
      };

      const isNewElement = isNaN(editedElement.id);
      if (isNewElement) {
        this.changes.elements.create[editedElement.id] = _editedElement;
        return;
      }
      const isChanged =
        originalElement.userResponseText != editedElement.userResponseText ||
        originalElement.statusId != editedElement.statusId ||
        originalElement.selected?.toString() !=
          editedElement.selected?.toString() ||
        new Date(originalElement.dateTimeInput).getTime() !=
          new Date(editedElement.dateTimeInput).getTime();

      if (isChanged) {
        this.changes.elements.update[editedElement.id] = _editedElement;
      } else {
        delete this.changes.elements.update[editedElement.id];
      }
    },
    isElementNotApplicable(statusId) {
      return statusId == this.$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE;
    },
    touchTheForm() {
      const params = {
        formInstanceId: this.formInstance.id,
      };
      this.$axios
        .post('touch-the-form-for-sync?format=json', params)
        .then(() => {
          this.formInstance.isPastSync = false;
          this.edited.isPastSync = false;
        })
        .catch((error) => error);
    },
    resetMenuCrumb() {
      if (this.isPreview) {
        this.$store.commit('updateSelectedFormTemplate', { name: '' });
        return;
      }
      this.$store.commit('updateSelectedFormInstance', {
        name: '',
      });
    },
    init() {
      this.resetMenuCrumb();
      if (this.isPreview) {
        return this.previewFormTemplate();
      }

      return this.getFormInstanceDetails();
    },
    previewFormTemplate() {
      const params = {
        loaderText: 'Loading...',
        id: this.$route.params.id,
        isPublic: this.$store.getters.isInternalAdmin,
        disableAutoError: true,
      };
      const url = params.isPublic
        ? 'preview-public-form-template?format=json'
        : 'preview-form-template?format=json';
      return this.$axios
        .post(url, params)
        .then((response) => {
          this.handleInitRequestResponse(response.data.preview);
          this.updateMenuBreadcrumb(response.data);
        })
        .catch((error) => {
          this.$root.showMessage(
            '',
            error.response.data,
            () =>
              this.$router.replace({
                name: params.isPublic ? 'PublicFormTemplates' : 'FormTemplates',
              }),
            null,
            'ok',
            null,
            true,
          );
        });
    },
    handleInitRequestResponse(responseData) {
      this.formInstance = responseData;
      this.edited = JSON.parse(JSON.stringify(this.formInstance));
      this.loading = false;
      this.reload = !this.reload;
      this.$nextTick(() => {
        this.setupSectionNavAvailableWidth();
      });
    },
    updateMenuBreadcrumb(responseData) {
      if (this.isPreview) {
        const formTemplate = responseData.formTemplate;
        this.$store.commit('updateSelectedFormTemplate', {
          isOrdering: false,
          isPublished: formTemplate.isPublished,
          groupId: formTemplate.formTemplateGroupId,
          name: formTemplate.name,
          id: formTemplate.id,
          groupName: formTemplate.formTemplateGroupName,
        });
        return;
      }
      this.$store.commit('updateSelectedFormInstance', {
        name: responseData.userEnteredLabel.trim(),
      });
    },
    getFormInstanceDetails() {
      const params = {
        loaderText: 'Loading...',
        id: this.$route.params.id,
      };

      const url = 'get-form-instance-details?format=json';

      return this.$axios.post(url, params).then((response) => {
        this.handleInitRequestResponse(response.data);
        this.updateMenuBreadcrumb(response.data);
      });
    },
    getNecessaryData() {
      this.isLoadingExtraData = true;
      const formInstanceId = this.isPreview ? -1 : this.$route.params.id;
      const params = {
        id: formInstanceId,
      };
      const url = 'get-necessary-data-for-form-details?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          if (!this.$store.getters.isInternalAdmin) {
            this.projects = response.data.projects;
            this.users = response.data.users;
            this.tags = response.data.tags;
            this.contacts = response.data.contacts;
          }
          this.severities = response.data.severities;
          this.severities.forEach((s) => (s.code = s.code.toString()));
          this.frequencies = response.data.frequencies;
          this.probabilities = response.data.probabilities;
          this.$nextTick(() => {
            if (this.$refs.form) {
              this.$refs.form.validate();
            }
          });
          this.isLoadingExtraData = false;
        })
        .catch((error) => error);
    },
    setupSectionNavAvailableWidth() {
      const padding = 20;
      this.sectionNavAvailableWidth =
        this.$refs.container.clientWidth -
        this.$refs.main.clientWidth -
        padding;
    },
    goToSection(index) {
      const options = {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
      };

      if (index >= 0) {
        this.$vuetify.goTo(this.$refs.sections[index], options);
        return;
      }

      if (index == -1) {
        this.$vuetify.goTo(this.$refs['form-summary'], options);
        return;
      }

      this.$vuetify.goTo(this.$refs['signatures'], options);
    },
    takeOwnership() {
      if (this.$store.getters.user.isPhantomUser) {
        this.$root.showMessage(
          'Cannot take ownership',
          'You can only take ownership of forms in your home company.',
          () => false,
          null,
          'OK',
          null,
        );
        return;
      }
      const params = {
        loaderText: 'Loading...',
        id: this.formInstance.id,
      };
      const url = 'form-instance-take-ownership?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.formInstance.assignedStudentUserId = this.$store.getters.user.id;
          this.edited.assignedStudentUserId = this.$store.getters.user.id;
          this.formInstance.assignedToName = this.userFullname;
          this.edited.assignedToName = this.userFullname;
          this.formInstance.updatedByName = this.userFullname;
          this.edited.updatedByName = this.userFullname;
        })
        .catch((error) => error);
    },
    changeFormPrivacyStatus() {
      const params = {
        loaderText: 'Loading...',
        id: this.formInstance.id,
      };
      const url = 'change-form-instance-privacy-status?format=json';
      this.$axios
        .post(url, params)
        .then(() => {
          this.formInstance.isPrivate = !this.formInstance.isPrivate;
          this.edited.isPrivate = !this.edited.isPrivate;
          this.formInstance.updatedByName = this.userFullname;
          this.edited.updatedByName = this.userFullname;
        })
        .catch((error) => error);
    },
    onSignatureCreated(payload, signatureTypeId) {
      const signature = {
        contactCompanyName: payload.contactCompanyName,
        signedByName: payload.signedByName,
        createdByName: payload.createdByName,
        createdOnMobileAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        typeId: signatureTypeId,
        guid: payload.guid,
        studentUserId: payload.studentUserId,
        id: payload.guid,
        comment: payload.comment,
        createdOnMobileBy: this.$store.getters.user.id,
        src: payload.dataURL,
        latitude: this.latitude,
        longitude: this.longitude,
        contactFullname: payload.contactFullname,
        contactGuid: payload.contactGuid,
      };
      switch (signatureTypeId) {
        case this.$constants.SIGNATURE_TYPES.CREATED_BY:
          this.$set(this.edited.signatures, 'createdBy', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.SUPERVISOR:
          this.$set(this.edited.signatures, 'supervisor', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.SAFETY_REP:
          this.$set(this.edited.signatures, 'safetyRep', signature);
          break;
        case this.$constants.SIGNATURE_TYPES.ADDITIONAL:
          this.edited.signatures.additional.push(signature);
          break;
        case this.$constants.SIGNATURE_TYPES.MANAGER:
          this.$set(this.edited.signatures, 'manager', signature);
          break;
        default:
          break;
      }

      this.binaries[payload.guid] = payload;
      this.changes.signatures.push({
        typeId: signatureTypeId,
        guid: payload.guid,
        studentUserId: payload.studentUserId,
        id: payload.guid,
        comment: payload.comment,
        latitude: this.latitude,
        longitude: this.longitude,
        contactGuid: payload.contactGuid,
        createdAt: new Date().toISOString(),
      });

      this.selectedSignatureTypeId = null;

      if (
        this.edited.formInstanceStatusId ==
          this.$constants.FORM_INSTANCE_STATUS.DRAFT &&
        !this.isPreview
      ) {
        this.save();
      }
    },
    async addNote(payload) {
      const note = {
        id: await this.createGuid(),
        description: payload,
        createdOnMobileAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        createdByName: this.userFullname,
        formInstanceElementId: this.selectedElement.id,
        formInstanceSectionId: this.selectedElement.formInstanceSectionId,
        createdOnMobileBy: this.$store.getters.user.id,
        position: this.selectedElement.notes.length + 1,
        createdAt: new Date().toISOString(),
      };
      this.selectedElement.notes.push(note);
      this.changes.notes[note.id] = note;
    },
    deleteNote(index) {
      const note = this.selectedElement.notes[index];
      this.selectedElement.notes.splice(index, 1);
      delete this.changes.notes[note.id];
    },
    addImage(payload) {
      const image = {
        id: payload.guid,
        guid: payload.guid,
        createdAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        formInstanceElementId: this.selectedElement.id,
        formInstanceSectionId: this.selectedElement.formInstanceSectionId,
        createdBy: this.$store.getters.user.id,
        src: payload.dataURL,
      };
      this.binaries[image.id] = payload;
      this.selectedElement.images.push(image);
      this.changes.images[image.id] = {
        id: payload.guid,
        guid: payload.guid,
        formInstanceElementId: this.selectedElement.id,
        formInstanceSectionId: this.selectedElement.formInstanceSectionId,
        createdAt: new Date().toISOString(),
      };
    },
    deleteImage(index) {
      const image = this.selectedElement.images[index];
      delete this.binaries[image.id];
      delete this.changes.images[image.id];
      this.selectedElement.images.splice(index, 1);
    },
    async showNewCa() {
      this.selectedCa = JSON.parse(
        JSON.stringify(this.defaultCorrectiveActionProps),
      );
      this.selectedCa.formInstanceSectionId =
        this.selectedElement.formInstanceSectionId;
      this.selectedCa.formInstanceElementId = this.selectedElement.id;
      this.selectedCa.id = await this.createGuid();

      this.$refs.caDetails.show();
    },
    deleteCa(index) {
      const correctiveAction = this.selectedElement.correctiveActions[index];

      correctiveAction.images.forEach((image) => {
        delete this.binaries[image.id];
        delete this.changes.correctiveActionImages[image.id];
      });

      correctiveAction.notes.forEach((note) => {
        delete this.changes.correctiveActionNotes[note.id];
      });

      this.selectedElement.correctiveActions.splice(index, 1);

      const isNewCorrectiveAction = isNaN(correctiveAction.id);
      if (isNewCorrectiveAction) {
        delete this.changes.correctiveActions.create[correctiveAction.id];
      } else {
        delete this.changes.correctiveActions.update[correctiveAction.id];
      }

      // when the last ca is deleted, check if the element status needs to be updated
      if (!this.selectedElement.correctiveActions.length) {
        this.checkElementStatus();
      }
    },
    checkElementStatus() {
      const elementIndex = this.selectedSection.elements.findIndex(
        (element) => element.id == this.selectedElement.id,
      );
      const sectionIndex = this.edited.sections.findIndex(
        (section) => section.id == this.selectedSection.id,
      );

      const originalElement =
        this.formInstance.sections[sectionIndex].elements[elementIndex];
      if (originalElement) {
        this.updateElement(sectionIndex, elementIndex, {
          key: 'statusId',
          value: originalElement.statusId,
        });
      } else {
        this.updateElement(sectionIndex, elementIndex, {
          key: 'statusId',
          value: '',
        });
      }

      // if an addition hazard element does not have any ca, it should be deleted
      if (
        this.selectedSection.typeId ==
        this.$constants.FORM_INSTANCE_SECTION_TYPES.ADDITIONAL_HAZARD
      ) {
        this.deleteElement(this.selectedElement.id, elementIndex);
        this.$refs.elementDetails.close();
      }
    },
    // this only applies to additional hazards section which will always be at the end
    deleteElement(id, index) {
      this.selectedElement.images.forEach((image) => {
        delete this.binaries[image.id];
        delete this.changes.images[image.id];
      });
      this.selectedElement.notes.forEach((note) => {
        delete this.changes.notes[note.id];
      });
      this.selectedElement.correctiveActions.forEach((ca) => {
        ca.images.forEach((image) => {
          delete this.binaries[image.id];
          delete this.changes.correctiveActionImages[image.id];
        });
        ca.notes.forEach((note) => {
          delete this.changes.correctiveActionNotes[note.id];
        });
        const isNewCa = isNaN(ca.id);
        if (isNewCa) {
          delete this.changes.correctiveActions.create[ca.id];
        } else {
          delete this.changes.correctiveActions.update[ca.id];
        }
      });
      this.selectedSection.elements.splice(index, 1);
      this.selectedSection = null;
      this.selectedElement = null;
      const isNewElement = isNaN(id);
      if (isNewElement) {
        delete this.changes.elements.create[id];
      } else {
        delete this.changes.elements.update[id];
      }
    },
    addSignature(signatureTypeId) {
      this.selectedSignatureTypeId = signatureTypeId;
      this.$refs.addSignature.show(signatureTypeId);
    },
    resetChanges() {
      this.binaries = {};
      this.changes = {
        sections: {},
        elements: {
          create: {},
          update: {},
        },
        signatures: [],
        tagIds: [],
        notes: {},
        images: {},
        correctiveActions: {
          create: {},
          update: {},
        },
        correctiveActionNotes: {},
        correctiveActionImages: {},
      };
    },
    redirectUserWithoutAccess() {
      const formIsAssigned =
        this.formInstance.assignedStudentUserId !=
        this.edited.assignedStudentUserId;
      let warningMessage = '';
      if (formIsAssigned) {
        warningMessage = 'Form is assigned.';
      } else {
        warningMessage = 'Your changes have been saved.';
      }

      this.$root.showMessage(
        '',
        warningMessage,
        () => {
          this.formInstance = JSON.parse(JSON.stringify(this.edited));
          this.$router.replace({ name: 'Forms' });
        },
        null,
        'OK',
        null,
        true,
      );
    },
    checkUserAccess() {
      const params = {
        id: this.formInstance.id,
        loaderText: 'Loading...',
      };
      return this.$axios.post('check-user-access-to-form?format=json', params);
    },
    async save() {
      if (this.formUpdateUrl == '') {
        return;
      }
      this.checkIsFormReviewed();

      // although code will stop if an error happened
      // the try catch here is just to prevent the error from being thrown to the console
      try {
        await this.updateToDB();
        await this.uploadFiles();
      } catch (error) {
        this.revertStatus();
        return;
      }

      const response = await this.checkUserAccess();
      const hasAccess = response.data;
      if (!hasAccess) {
        this.redirectUserWithoutAccess();
        return;
      }
      this.resetChanges();
      await this.getFormInstanceDetails();
      this.$store.commit('updateSelectedFormInstance', {
        name: this.edited.userEnteredLabel,
      });
    },
    updateToDB() {
      const formInstance = {
        assignedStudentUserId: this.edited.assignedStudentUserId,
        formInstanceStatusId: this.edited.formInstanceStatusId,
        id: this.edited.id,
        projectId: this.edited.projectId,
        timeIn: this.edited.timeIn,
        timeOut: this.edited.timeOut,
        userEnteredLabel: this.edited.userEnteredLabel,
        supervisorApprovalRequired: this.edited.supervisorApprovalRequired,
        managerApprovalRequired: this.edited.managerApprovalRequired,
        safetyRepApprovalRequired: this.edited.safetyRepApprovalRequired,
      };
      this.changes.tagIds = this.edited.tagIds;

      const params = {
        changes: {
          formInstance,
          ...this.changes,
        },
        loaderText:
          'Saving form information. This may take a few minutes. Please do not close the tab.',
      };
      return this.$axios.post(this.formUpdateUrl, params);
    },
    async uploadFiles() {
      const binaries = Object.values(this.binaries);
      for (let i = 0; i < binaries.length; i++) {
        const binary = binaries[i];
        const index = i + 1;
        const length = binaries.length;
        await this.uploadBinary(binary, index, length);
      }
    },
    revertStatus() {
      if (
        this.formInstance.formInstanceStatusId !=
        this.edited.formInstanceStatusId
      ) {
        this.edited.formInstanceStatusId =
          this.formInstance.formInstanceStatusId;
      }
    },
    uploadBinary(payload, index, length) {
      const url = 'upload-binary-for-form-instance';
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const params = new FormData();
      params.append('file', payload.binary);
      params.append('guid', payload.guid);
      params.append('jwtToken', this.$store.getters.jwtToken);
      params.append('loaderText', `Uploading file (${index}/${length})...`);

      return this.$axios.post(url, params, headers);
    },
    assignForm(payload) {
      this.edited.assignedStudentUserId = payload.id;
      this.edited.assignedToName = payload.fullname;
      if (
        this.edited.formInstanceStatusId ==
          this.$constants.FORM_INSTANCE_STATUS.DRAFT ||
        this.edited.formInstanceStatusId ==
          this.$constants.FORM_INSTANCE_STATUS.ASSIGNED
      ) {
        this.edited.formInstanceStatusId =
          this.$constants.FORM_INSTANCE_STATUS.SUBMITTED;
      }
      this.save();
    },
    checkIsFormReviewed() {
      if (
        this.edited.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return;
      }

      const needReview =
        this.formInstance.supervisorApprovalRequired ||
        this.formInstance.managerApprovalRequired ||
        this.formInstance.safetyRepApprovalRequired;
      let isReviewed = false;
      if (needReview) {
        let allRequiredSignaturesAdded = true;
        if (
          this.edited.managerApprovalRequired &&
          !this.hasSignature(this.edited.signatures.manager)
        ) {
          allRequiredSignaturesAdded = false;
        }

        if (
          this.edited.supervisorApprovalRequired &&
          !this.hasSignature(this.edited.signatures.supervisor)
        ) {
          allRequiredSignaturesAdded = false;
        }

        if (
          this.edited.safetyRepApprovalRequired &&
          !this.hasSignature(this.edited.signatures.safetyRep)
        ) {
          allRequiredSignaturesAdded = false;
        }
        isReviewed = allRequiredSignaturesAdded;
      } else {
        isReviewed =
          this.hasSignature(this.edited.signatures.manager) ||
          this.hasSignature(this.edited.signatures.supervisor) ||
          this.hasSignature(this.edited.signatures.safetyRep);
      }
      if (isReviewed) {
        this.edited.formInstanceStatusId =
          this.$constants.FORM_INSTANCE_STATUS.REVIEWED;
      }
    },
    updateProjectId(event) {
      if (!event) {
        this.edited.projectId = 0;
      } else {
        this.edited.projectId = event;
      }
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    },
    viewOnMap(signature) {
      if (
        !this.hasSignature(signature) ||
        signature.latitude == '' ||
        signature.longitude == ''
      ) {
        return;
      }

      this.selectedSignatureLocation.latitude = signature.latitude;
      this.selectedSignatureLocation.longitude = signature.longitude;
      this.$refs.signatureMap.show();
    },
    finalizeForm() {
      const confirm = () => {
        this.edited.formInstanceStatusId =
          this.$constants.FORM_INSTANCE_STATUS.FINALIZED;
        this.save();
        window.scrollTo(0, 0);
      };
      if (this.caCounts.unresolved > 0) {
        this.$root.showMessage(
          'Unresolved corrective action(s)',
          'You are about to finalize a form with unresolved corrective action(s).',
          () => confirm(),
          () => false,
          'Finalize',
          'Cancel',
        );
        return;
      }

      this.$root.showMessage(
        'Finalize form?',
        'Finalized forms cannot be changed in the future. They are for reference only.',
        () => confirm(),
        () => false,
        'Finalize Form',
        'Cancel',
      );
    },
    downloadForm(
      areImagesCompressed = true,
      imagesOnly = false,
      includeAudits = false,
      includeAttachments = false,
    ) {
      const params = {
        loaderText: 'Loading...',
        formInstanceId: this.edited.id,
        areImagesCompressed,
        imagesOnly,
        includeAudits,
        includeAttachments,
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const url = 'download-form-instance-as-pdf?format=json';
      this.$axios
        .post(url, params, options)
        .then((response) => {
          const filename =
            this.generateFilenameForFormDownload(includeAttachments);
          this.$helpers.downloadFile(response.data, filename);
        })
        .catch((error) => error);
    },
    generateFilenameForFormDownload(includeAttachments) {
      const date = DateTime.fromISO(
        new Date(this.formInstance.createdOnMobileAt).toISOString(),
      ).toFormat('yyyy-LLL-dd');
      const filename = this.formInstance.userEnteredLabel.slice(0, 100).trim();
      const suffix = `${this.formInstance.id}-${date}`;
      let extension = 'pdf';
      if (includeAttachments) {
        extension = 'zip';
      }
      return `${filename}-${suffix}.${extension}`;
    },
    saveCa(correctiveAction) {
      if (correctiveAction.createdOnMobileAt == undefined) {
        this.addCa(correctiveAction);
      } else {
        this.updateCa(correctiveAction);
      }
      this.selectedCa = null;
    },
    addCa(correctiveAction) {
      this.updateCa(correctiveAction);
      this.selectedCa.createdOnMobileAt =
        DateTime.now().toFormat('yyyy-LL-dd HH:mm');
      this.selectedElement.correctiveActions.push(this.selectedCa);
      const elementIndex = this.selectedSection.elements.findIndex(
        (element) => element.id == this.selectedElement.id,
      );
      const sectionIndex = this.edited.sections.findIndex(
        (section) => section.id == this.selectedSection.id,
      );

      // update element status when adding first CA
      if (
        this.selectedElement.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM &&
        this.selectedElement.statusId != 2
      ) {
        this.updateElement(sectionIndex, elementIndex, {
          key: 'statusId',
          value: 2,
        });
      }

      if (
        this.selectedElement.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS &&
        this.selectedElement.statusId != 1
      ) {
        this.updateElement(sectionIndex, elementIndex, {
          key: 'statusId',
          value: 1,
        });
      }

      // update element name when adding first CA for additional hazard
      if (
        this.selectedElement.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS &&
        this.selectedElement.name.trim().length == 0
      ) {
        this.updateElement(sectionIndex, elementIndex, {
          key: 'name',
          value: this.selectedCa.hazardText,
        });
      }
    },
    updateCa(correctiveAction) {
      this.selectedCa.hazardRankSeverity = correctiveAction.hazardRankSeverity;
      this.selectedCa.hazardRankProbability =
        correctiveAction.hazardRankProbability;
      this.selectedCa.hazardText = correctiveAction.hazardText;
      this.selectedCa.controlText = correctiveAction.controlText;
      this.selectedCa.frequencyCode = correctiveAction.frequencyCode;
      this.selectedCa.assignedStudentUserId =
        correctiveAction.assignedStudentUserId;
      this.selectedCa.assignedToName = correctiveAction.assignedToName;
      this.selectedCa.lastResolvedAt = correctiveAction.lastResolvedAt;
      this.selectedCa.lastResolvedByName = correctiveAction.lastResolvedByName;
      this.selectedCa.lastResolvedBy = correctiveAction.lastResolvedBy;
      this.selectedCa.statusId = correctiveAction.statusId;
      const sectionId = correctiveAction.formInstanceSectionId;
      const elementId = correctiveAction.formInstanceElementId;
      const section = this.formInstance.sections.find(
        (section) => section.id == sectionId,
      );
      const element = section.elements.find(
        (element) => element.id == elementId,
      );
      const updated = {
        id: this.selectedCa.id,
        hazardRankSeverity: this.selectedCa.hazardRankSeverity,
        hazardRankProbability: this.selectedCa.hazardRankProbability,
        hazardText: this.selectedCa.hazardText,
        controlText: this.selectedCa.controlText,
        frequencyCode: this.selectedCa.frequencyCode,
        assignedStudentUserId: this.selectedCa.assignedStudentUserId,
        lastResolvedAt: this.selectedCa.lastResolvedAt,
        lastResolvedBy: this.selectedCa.lastResolvedBy,
        statusId: this.selectedCa.statusId,
        formInstanceElementId: this.selectedCa.formInstanceElementId,
        formInstanceSectionId: this.selectedCa.formInstanceSectionId,
      };
      if (element) {
        const originalCa = element.correctiveActions.find(
          (ca) => ca.id == correctiveAction.id,
        );
        if (originalCa) {
          const original = {
            id: originalCa.id,
            hazardRankSeverity: originalCa.hazardRankSeverity,
            hazardRankProbability: originalCa.hazardRankProbability,
            hazardText: originalCa.hazardText,
            controlText: originalCa.controlText,
            frequencyCode: originalCa.frequencyCode,
            assignedStudentUserId: originalCa.assignedStudentUserId,
            lastResolvedAt: originalCa.lastResolvedAt,
            lastResolvedBy: originalCa.lastResolvedBy,
            statusId: originalCa.statusId,
            formInstanceElementId: originalCa.formInstanceElementId,
            formInstanceSectionId: originalCa.formInstanceSectionId,
          };
          if (JSON.stringify(original) != JSON.stringify(updated)) {
            if (isNaN(updated.id)) {
              this.changes.correctiveActions.create[updated.id] = {
                ...updated,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
              };
            } else {
              this.changes.correctiveActions.update[updated.id] = {
                ...updated,
                createdAt: originalCa.createdAt,
                updatedAt: new Date().toISOString(),
              };
            }
          } else {
            if (isNaN(updated.id)) {
              delete this.changes.correctiveActions.create[updated.id];
            } else {
              delete this.changes.correctiveActions.update[updated.id];
            }
          }
          return;
        }
      }
      this.changes.correctiveActions.create[updated.id] = {
        ...updated,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
    },
    showCaDetails(ca) {
      this.selectedCa = ca;
      this.$refs.caDetails.show();
    },
    addCaImage(payload) {
      payload.type = this.$constants.BINARY_TYPES.CA_IMAGE;
      const image = {
        id: payload.guid,
        guid: payload.guid,
        createdAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        correctiveActionId: this.selectedCa.id,
        formInstanceElementId: this.selectedCa.id,
        formInstanceSectionId: this.selectedCa.formInstanceSectionId,
        createdBy: this.$store.getters.user.id,
        src: payload.dataURL,
      };
      this.binaries[image.id] = payload;
      this.selectedCa.images.push(image);
      this.changes.correctiveActionImages[image.id] = {
        id: payload.guid,
        guid: payload.guid,
        correctiveActionId: this.selectedCa.id,
        formInstanceElementId: this.selectedCa.id,
        formInstanceSectionId: this.selectedCa.formInstanceSectionId,
        createdAt: new Date().toISOString(),
      };
    },
    deleteCaImage(index) {
      const image = this.selectedCa.images[index];
      delete this.binaries[image.id];
      this.selectedCa.images.splice(index, 1);
      delete this.changes.correctiveActionImages[image.id];
    },
    async addCaNote(payload) {
      const note = {
        id: await this.createGuid(),
        description: payload,
        createdOnMobileAt: DateTime.now().toFormat('yyyy-LL-dd HH:mm'),
        createdByName: this.userFullname,
        correctiveActionId: this.selectedCa.id,
        formInstanceElementId: this.selectedCa.formInstanceElementId,
        formInstanceSectionId: this.selectedCa.formInstanceSectionId,
        createdOnMobileBy: this.$store.getters.user.id,
        position: this.selectedCa.notes.length + 1,
        createdAt: new Date().toISOString(),
      };
      this.selectedCa.notes.push(note);
      this.changes.correctiveActionNotes[note.id] = note;
    },
    deleteCaNote(index) {
      const note = this.selectedCa.notes[index];
      this.selectedCa.notes.splice(index, 1);
      delete this.changes.correctiveActionNotes[note.id];
    },
    async addAdditionalHazard() {
      this.selectedSection =
        this.edited.sections[this.edited.sections.length - 1];
      const position = this.selectedSection.elements.length + 1;
      const element = {
        id: await this.createGuid(),
        typeId: this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS,
        statusId: 1,
        userResponseText: '',
        formInstanceSectionId: this.selectedSection.id,
        notes: [],
        images: [],
        correctiveActions: [],
        attachments: [],
        description: '',
        inputRequired: 1,
        name: '',
        position,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
      this.selectedSection.elements.push(element);
      this.selectedElement = element;
      this.changes.elements.create[element.id] = {
        formInstanceSectionId: element.formInstanceSectionId,
        id: element.id,
        name: element.name,
        position: element.position,
        revision: element.revision,
        statusId: element.statusId,
        typeId: element.typeId,
        userResponseText: element.userResponseText,
        inputRequired: element.inputRequired,
        createdAt: element.createdAt,
        updatedAt: element.updatedAt,
      };
      this.showNewCa();
    },
    sectionNA(section, sectionIndex, isCollapsed) {
      // Prevent overwriting of values on finalizes forms
      if (
        this.formInstance.formInstanceStatusId ==
        this.$constants.FORM_INSTANCE_STATUS.FINALIZED
      ) {
        return;
      }

      if (isCollapsed) {
        section.elements.forEach((element, elementIndex) => {
          switch (element.typeId) {
            case this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM:
            case this.$constants.FORM_ELEMENT_TYPES.YES_NO:
            case this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS:
              if (element.statusId <= 0) {
                this.updateElement(sectionIndex, elementIndex, {
                  key: 'statusId',
                  value: this.$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE,
                });
              }
              break;

            case this.$constants.FORM_ELEMENT_TYPES.TEXT_FIELD:
              if (element.userResponseText.trim() == '') {
                this.updateElement(sectionIndex, elementIndex, {
                  key: 'userResponseText',
                  value: 'NA',
                });
              }
              break;

            case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_SINGLE:
            case this.$constants.FORM_ELEMENT_TYPES.DROP_DOWN_MULTI:
            case this.$constants.FORM_ELEMENT_TYPES.DATE:
            case this.$constants.FORM_ELEMENT_TYPES.TIME:
              if (!this.isElementNotApplicable(element.statusId)) {
                this.updateElement(sectionIndex, elementIndex, {
                  key: 'statusId',
                  value: this.$constants.FORM_ELEMENT_STATUS.NOT_APPLICABLE,
                });
              }
              break;

            case this.$constants.FORM_ELEMENT_TYPES.PDF:
              if (
                element.statusId !=
                this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY
              ) {
                this.updateElement(sectionIndex, elementIndex, {
                  key: 'statusId',
                  value: this.$constants.FORM_ELEMENT_STATUS.SATISFACTORY,
                });
              }
              break;
          }
        });
      }

      const originalSection = this.formInstance.sections[sectionIndex];
      if (originalSection.isCollapsed != isCollapsed) {
        this.changes.sections[section.id] = {
          id: section.id,
          isCollapsed,
        };
      } else {
        delete this.changes.sections[section.id];
      }
    },
    unsavedWarning(e) {
      if (
        this.hasChanges &&
        !this.edited.signatures.completedBy &&
        !this.isPreview
      ) {
        e.preventDefault();
        // a random value here is to trigger the browser's native warning
        return (e.returnValue = '_');
      }
    },
    async createGuid() {
      const url = 'create-guid?format=json';
      return this.$axios
        .post(url, { loaderText: 'Loading...' })
        .then((response) => response.data);
    },
    closeCaDetailsDialog() {
      const elementIndex = this.selectedSection.elements.findIndex(
        (element) => element.id == this.selectedElement.id,
      );
      const sectionIndex = this.edited.sections.findIndex(
        (section) => section.id == this.selectedSection.id,
      );
      const caExists = this.selectedElement.correctiveActions.some(
        (ca) => ca.id == this.selectedCa.id,
      );

      // someone creates a CA but then cancelled it without saving
      if (!caExists) {
        this.selectedCa.images.forEach((image) => {
          delete this.binaries[image.id];
          delete this.changes.correctiveActionImages[image.id];
        });
        this.selectedCa.notes.forEach((note) => {
          delete this.changes.correctiveActionNotes[note.id];
        });
      }

      // if the element still has ca, we update the status to the correct value
      if (this.selectedElement.correctiveActions.length > 0) {
        if (
          this.selectedElement.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.INSPECTION_ITEM
        ) {
          this.updateElement(sectionIndex, elementIndex, {
            key: 'statusId',
            value: 2,
          });
        }
        if (
          this.selectedElement.typeId ==
          this.$constants.FORM_ELEMENT_TYPES.COMMON_HAZARDS
        ) {
          this.updateElement(sectionIndex, elementIndex, {
            key: 'statusId',
            value: 1,
          });
        }
      } else {
        // when there are no CA left and the element is not new, we flip the status back to the original
        if (
          this.formInstance.sections[sectionIndex] &&
          this.formInstance.sections[sectionIndex].elements[elementIndex]
        ) {
          this.updateElement(sectionIndex, elementIndex, {
            key: 'statusId',
            value: (this.selectedElement.statusId =
              this.formInstance.sections[sectionIndex].elements[
                elementIndex
              ].statusId),
          });
        } else {
          // when the element is new and has no CA, we delete it
          this.deleteElement(this.selectedElement.id, elementIndex);
        }

        this.selectedElement = null;
      }
      this.selectedCa = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-chip {
  /* chip background color msaBlue */
  background-color: #3564ac;

  /* chip text color */
  color: white;

  /* chip X color */
  button {
    color: white;
  }
}
</style>
